// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()

window.Rails = Rails

import 'bootstrap'
import 'data-confirm-modal'

const feather = require("feather-icons");
document.addEventListener("turbolinks:load", function() {
    feather.replace();
})

var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import 'select2'
import 'select2/dist/css/select2.css'
// import '../../assets/theme/Dashkit/Dashkit-1.6.1/dist/assets/js/dashkit.min'
import 'theme/theme.min'
import 'theme/dashkit.min'
// import '../../assets/theme/Dashkit/Dashkit-1.6.1/dist/assets/js/theme.min'
// import '../../assets/theme/Dashkit/Dashkit-1.6.1/dist/assets/js/theme.min'

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  // $('.select2').select2();
    $(".wrapper tr").prettyTextDiff();
    $("del").addClass('removed')
    $("ins").addClass('added')

    $('th.sortable').click(function(){
        var table = $(this).parents('table').eq(0);
        var ths = table.find('tr:gt(0)').toArray().sort(compare($(this).index()));
        this.asc = !this.asc;
        if (!this.asc)
            ths = ths.reverse();
        for (var i = 0; i < ths.length; i++)
            table.append(ths[i]);
    });

    function compare(idx) {
        return function(a, b) {
            var A = tableCell(a, idx), B = tableCell(b, idx)
            return $.isNumeric(A) && $.isNumeric(B) ?
                A - B : A.toString().localeCompare(B)
        }
    }

    function tableCell(tr, index){
        return $(tr).children('td').eq(index).text()
    }
})
